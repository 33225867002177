import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
// Firebase
import { FirebaseContext } from "../../firebase"
import Button from "../elements/button/Button";
import {RiCheckLine, RiCloseLine, RiLoader4Line} from "react-icons/ri";
import {capitalizeFirstLetter} from "../../utils/stringUtils";
import InputText from "../forms/input/InputText";
import {PlayNotifications} from "../../redux/notifications/PlayNotifications";

const CreditsForm = ({closeForm, project, action}) => {
  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard']);

  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext);

  const [formLoading, setFormLoading] = useState(false);
  const [credits, setCredits] = useState((project.current_credits && action === 'update') ? project.current_credits : 0);

  const updateCredits = () => {
    setFormLoading(true);
    const changeCreditsFunction = firebase.functions.httpsCallable('projects-updateCredits');

    changeCreditsFunction({
      action: action,
      credits: credits,
      project_id: project.id,
    }).then((result) => {
      if(result.data?.success){
        dispatch(
          PlayNotifications(
            {
              display:true,
              theme:"success",
              message: t('success.success', {ns: 'messages'}),
            }
          )
        );
        closeForm();
      } else {
        const error = result.data?.error ? result.data.error : null;
        console.error("Error deploy front: ", error);
        const errorMessage = result.data?.message ? result.data.message : 'Erreur';

        dispatch(
          PlayNotifications(
            {
              display: true,
              theme: "danger",
              message: errorMessage,
            }
          )
        );
      }

      setFormLoading(false);
    }).catch((error) => {
      console.error("Error change credit: ", error);
      const errorMessage = error?.message ? error.message : 'Erreur';

      dispatch(
        PlayNotifications(
          {
            display: true,
            theme: "danger",
            message: errorMessage,
          }
        )
      );
      setFormLoading(false);
    });
  }

  useEffect(() => {
    return () => {
      setCredits(0);
    }
  }, []);

  return (
    <div
      className="fixed z-30 inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full max-w-100vw w-full max-h-100vh py-10">
      <div
        className="relative p-5 pb-0 border shadow-lg rounded-md bg-white w-4/5 md:w-3/5 lg:w-2/5 max-h-full memoryScrollbar">
        <div className="flex items-center justify-between">
          <h2 className={`text-lg`}>
            {t('projects.Deploy-frontend', {ns: 'dashboard'})}
          </h2>
        </div>

        <div className="mt-3 flex flex-col gap-y-4">
          <div>
            <InputText
              id="credits"
              name="credits"
              label={t('projects.form.credits.label', {ns: 'dashboard'})}
              placeHolder={t('projects.form.credits.placeholder', {ns: 'dashboard'})}
              state={credits}
              setstate={(e) => setCredits(e.target.value)}
              // loading={disabled}
            />
            <p className={`text-xs text-grayPrimary`}>
              {t('projects.form.credits.notice', {ns: 'dashboard'})}
            </p>
          </div>
        </div>

        <div
          className="flex items-end justify-between border-t border-gray-200 pb-5 pt-2 mt-10 sticky top-0 bottom-0 bg-white">
          <div className="flex flex-col gap-4 min480:flex-row">
            <Button
              theme="simple"
              size="small"
              icon={<RiCloseLine className="w-4 h-4 mr-1"/>}
              actionType={"action"}
              action={() => closeForm()}
            >
              {t('action.Back', {ns: 'common'})}
            </Button>
          </div>

          <Button
            theme={formLoading ? 'disabled' : ''}
            size="small"
            icon={formLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin"/> :
              <RiCheckLine className="w-4 h-4 ml-1"/>}
            iconPosition="right"
            actionType="action"
            action={() => updateCredits()}
          >
            {t('action.Confirm', {ns: 'common'})}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreditsForm;