import React, {useState, useEffect, useContext, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
// Firebase
import { FirebaseContext } from "../../../firebase/"
// Redux
import {useDispatch} from "react-redux"
import { PlayNotifications } from 'redux/notifications/PlayNotifications'
import LayoutDashboard from "components/layout/LayoutDashboard";
import {PROJECTS_MENU} from "utils/constantsBackOffice";
import {
  RiAddLine,
  RiArrowLeftLine, RiBarChartBoxLine,
  RiBuildingLine, RiCalendarEventFill,
  RiCodeBoxLine, RiListOrdered2,
  RiLoader4Line, RiPencilLine, RiPencilRulerLine, RiRefreshLine,
  RiRobot2Line,
  RiSearchLine, RiSparkling2Line, RiUpload2Line
} from "react-icons/ri";
import Button from "components/elements/button/Button";
import Headings from "components/elements/Typography/Headings";
import _ from "lodash";
import {capitalizeFirstLetter} from "utils/stringUtils";
import moment from "moment/moment";
import ChangePlanForm from "components/modals/ChangePlanForm";
import ChangeModeForm from "components/modals/ChangeModeForm";
import DeployFrontForm from "components/modals/DeployFrontForm";
import CreditsForm from "components/modals/CreditsForm";
import {TbNorthStar} from "react-icons/tb";

const ProjectPage = ({ selectedMenu, currentUser }) => {
  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard']);

  const firebase = useContext(FirebaseContext);
  const dispatch = useDispatch();

  let currentUrl = useParams() // observes a change of url
  const [currentId, ] = useState(currentUrl.id ? currentUrl.id : null);

  const [ready, setReady] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [state, setState] = useState({});
  const [openChangePlan, setOpenChangePlan] = useState(false);
  const [openChangeMode, setOpenChangeMode] = useState(false);
  const [openDeployFront, setOpenDeployFront] = useState(false);
  const [openDeploy, setOpenDeploy] = useState(false);
  const [refreshCreditsLoading, setRefreshCreditsLoading] = useState(false);
  const [openCreditsForm, setOpenCreditsForm] = useState(false);
  const [openAddCreditsForm, setOpenAddCreditsForm] = useState(false);

  const availableOptions = [
    {id: 'api', name: t('projects.options.Api'), picto: <RiCodeBoxLine className={'w-4 h-4'} />},
    {id: 'ai.content_generative_ai', name: t('projects.options.Ai-content-generative-ai'), picto: <RiSparkling2Line className={'w-4 h-4'} />},
    {id: 'ai.prompts_families', name: t('projects.options.Ai-prompts-families'), picto: <RiSparkling2Line className={'w-4 h-4'} />},
    {id: 'automation', name: t('projects.options.Automation-flows'), picto: <RiRobot2Line className={'w-4 h-4'} />},
    {id: 'beta.search', name: t('projects.options.Beta-search'), picto: <RiSearchLine className={'w-4 h-4'} />},
    {id: 'content.edit_publication_date', name: t('projects.options.Edit-publication-date'), picto: <RiCalendarEventFill className={'w-4 h-4'} />},
    {id: 'gtm', name: t('projects.options.Google-tag-manager'), picto: <RiBarChartBoxLine className={'w-4 h-4'} />},
    {id: 'homepage.create', name: t('projects.options.Focus-page'), picto: <TbNorthStar className={'w-4 h-4'} />},
    {id: 'import.import_content', name: t('projects.options.Import-content'), picto: <RiUpload2Line className={'w-4 h-4'} />},
    {id: 'import.import_structure', name: t('projects.options.Import-structures'), picto: <RiUpload2Line className={'w-4 h-4'} />},
    {id: 'import.import_attributes', name: t('projects.options.Import-attributes'), picto: <RiUpload2Line className={'w-4 h-4'} />},
    {id: 'sort.reference_date', name: t('projects.options.Reference-date'), picto: <RiListOrdered2 className={'w-4 h-4'} />},
    {id: 'sort.reference_date_setting', name: t('projects.options.Reference-date-setting'), picto: <RiCalendarEventFill className={'w-4 h-4'} />},
    {id: 'theme.custom_colors', name: t('projects.options.Custom-colors'), picto: <RiPencilRulerLine className={'w-4 h-4'} />}
  ];

  const refreshCredits = () => {
    if(!refreshCreditsLoading){
      setRefreshCreditsLoading(true);
      const refreshCreditsFunction = firebase.functions.httpsCallable("projects-refreshCredits");

      refreshCreditsFunction({
        project_id: state.id
      }).then((result) => {
        console.log('finish', result);
        setRefreshCreditsLoading(false);

        if(result.data?.success === true){
          dispatch(
            PlayNotifications({
              display: true,
              theme: 'success',
              message: t('success.success', {ns: 'messages'}),
            })
          );
        } else {
          dispatch(
            PlayNotifications({
              display: true,
              theme: 'danger',
              message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
            })
          );
        }
      }).catch((error) => {
        setRefreshCreditsLoading(false);
        dispatch(
          PlayNotifications({
            display: true,
            theme: 'danger',
            message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
          })
        )
        console.error(error);
      })
    }
  }

  const setOption = (option, activated) => {
    console.log('setOption')
    setFormLoading(true)
    // setEntityOptions
    const setEntityOptionsFunction = firebase.functions.httpsCallable("setEntityOptions");
    setEntityOptionsFunction({
      options: {
        [option]: activated
      },
      project_id: state.id
    }).then((result) => {
      console.log('success !!', result)
      setFormLoading(false)

      if(result.data?.success === true){
        firebase.db.collection('projects').doc(currentId).update({
          [`options.${option}`]: activated
        }).catch((e)=>{
          console.error('Error during update', e)
        })

        dispatch(
          PlayNotifications({
            display: true,
            theme: 'success',
            message: t('success.saved-changes', {ns: 'messages'}),
          })
        )
      } else {
        dispatch(
          PlayNotifications({
            display: true,
            theme: 'danger',
            message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
          })
        )
      }
    }).catch((error) => {
      setFormLoading(false)
      dispatch(
        PlayNotifications({
          display: true,
          theme: 'danger',
          message: t('error.unknown-error-try-again-later', {ns: 'messages'}),
        })
      )
      console.error(error);
    });
  }

  const getOptionStatus = (option) => {
    return _.get(state, `options.${option}`) === true
  }

  const getOptionStatusTag = (option) => {
    if( _.get(state, `options.${option}`) === true ){
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-green-100 border border-green-300 text-xs font-semibold text-green-600 inline rounded-full p-0.5 px-2">
          {t('status.Activated', {ns: 'dashboard'})}
        </span>
      )
    } else if (_.get(state, `options.${option}`) === false) {
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-gray-100 border border-gray-300 text-xs font-semibold text-gray-600 inline rounded-full p-0.5 px-2">
          {t('status.Disabled', {ns: 'dashboard'})}
        </span>
      )
    } else if( _.get(state, `options.${option}`) === undefined ){
      return (
        <span className="cursor-pointer inline-flex gap-1 bg-red-100 border border-red-300 text-xs font-semibold text-red-600 inline rounded-full p-0.5 px-2">
          {t('status.Unset', {ns: 'dashboard'})}
        </span>
      )
    }

    return '-'
  }

  const closeForm = () => {
    setOpenChangePlan(false);
    setOpenChangeMode(false);
    setOpenDeploy(false);
    setOpenDeployFront(false);
    setOpenCreditsForm(false);
    setOpenAddCreditsForm(false);
  }

  useEffect(() => {
    document.title = `Memory - ${t('projects.Projects-management', {ns: 'dashboard'})}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let projectSnapUnsubscribe

    if(currentId){
      projectSnapUnsubscribe = firebase.db.collection('projects').doc(currentId).onSnapshot( async doc => {
        if (doc.exists) {
          const data = {...doc.data(), id: doc.id}
          setState(data)
          setReady(true)
        } else {
          setReady(true)
        }
      })
    }

    return () => {
      projectSnapUnsubscribe && projectSnapUnsubscribe()
    }
  }, [currentId])

  return (
    <>
      {openChangePlan &&
        <ChangePlanForm
          closeForm={() => closeForm()}
          project={state}
        />
      }
      {openChangeMode &&
        <ChangeModeForm
          closeForm={() => closeForm()}
          project={state}
        />
      }
      {openDeployFront &&
        <DeployFrontForm
          closeForm={() => closeForm()}
          project={state}
          type={'front'}
        />
      }
      {openDeploy &&
        <DeployFrontForm
          closeForm={() => closeForm()}
          project={state}
          type={'all'}
        />
      }
      {openCreditsForm &&
        <CreditsForm
          closeForm={() => closeForm()}
          project={state}
          action={'update'}
        />
      }
      {openAddCreditsForm &&
        <CreditsForm
          closeForm={() => closeForm()}
          project={state}
          action={'add'}
        />
      }

      <LayoutDashboard
        currentUser={currentUser}
        // headerNav={SUB_NAV_SETTINGS}
        headerNavSelected={PROJECTS_MENU}
        selectedMenu={selectedMenu}
        maxHeight={false}
      >
        <div id="sections-container" className="flex flex-col justify-between w-full h-full max-w-100vw overflow-auto memoryScrollbar">
          <div className="flex flex-col relative">
            <header className="z-40 bg-white w-full pb-6 sticky top-0">
              <ul className="flex items-center justify-between mb-2">
                <li className="flex items-center gap-2">
                  <RiBuildingLine className="w-5 h-5 min-w-5"/>
                  <Headings
                    variant="h2"
                    className="flex items-center"
                    display="display-4"
                  >
                    {t('projects.Project', {ns: 'dashboard'})}: {state.name ? state.name : state.id}
                  </Headings>
                </li>
              </ul>
            </header>

            <div className="flex w-full relative">
              <div className="w-full pb-5 space-y-2">
                <div className={`flex flex-col w-full h-full pb-2 pr-4 pb-0 bg-white overflow-visible`}>

                  <div className={`mb-4 text-xl font-semibold`}>
                    {t('projects.Informations', {ns: 'dashboard'})}
                  </div>
                  <div className={`flex flex-col gap-4`}>
                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('Name', {ns: 'common'})}</span>
                      <span className={`font-semibold`}>{state.name ? state.name : t('NC', {ns: 'dashboard'})}</span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('projects.Domain-subdomain', {ns: 'dashboard'})}</span>
                      <span
                        className={`font-semibold`}>{state.domain ? state.domain : state.subdomain ? state.subdomain : t('NC', {ns: 'dashboard'})}</span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('Plan', {ns: 'common'})}</span>
                      <span
                        className={`font-semibold`}>{state.plan ? t(capitalizeFirstLetter(state.plan), {ns: 'common'}) : t('NC', {ns: 'dashboard'})}</span>
                      <span
                        className={`w-fit cursor-pointer text-sm text-gray-600 hover:underline`}
                        onClick={() => setOpenChangePlan(true)}
                      >
                        {t('projects.Change-plan', {ns: 'dashboard'})}
                      </span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('Mode', {ns: 'common'})}</span>
                      <span
                        className={`font-semibold`}>{state.mode ? t(capitalizeFirstLetter(state.mode), {ns: 'common'}) : t('NC', {ns: 'dashboard'})}</span>
                      <span
                        className={`w-fit cursor-pointer text-sm text-gray-600 hover:underline`}
                        onClick={() => setOpenChangeMode(true)}
                      >
                        {t('projects.Change-mode', {ns: 'dashboard'})}
                      </span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('projects.Current-branch', {ns: 'dashboard'})}</span>
                      <span
                        className={`font-semibold`}>{state?.current_branch ? state.current_branch : t('NC', {ns: 'dashboard'})}</span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={``}>{t('date.Creation-date', {ns: 'common'})}</span>
                      <span
                        className={`font-semibold`}>{state.created_at ? moment(state.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}</span>
                    </div>

                    <div className={`flex flex-col gap-1`}>
                      <span className={`flex gap-2 items-center`}>
                        {t('Credits', {ns: 'common'})}
                        <span
                          className={`cursor-pointer rounded-full bg-white hover:bg-gray-200 p-1 w-fit animate ${refreshCreditsLoading ? 'animate-spin' : ''}`}
                          onClick={() => refreshCredits()}
                        >
                          <RiRefreshLine className={`w-4 h-4`} />
                        </span>
                      </span>
                      <span className={`flex gap-2 items-center`}>
                        <span className={`font-semibold px-2 py-1 bg-gray-100 rounded`}>{typeof state.current_credits !== undefined ? state.current_credits : t('NC', {ns: 'dashboard'})}</span>
                        <span className={`flex gap-1 items-center`}>
                          <span
                            className={`cursor-pointer rounded-full bg-white hover:bg-gray-200 p-1 w-fit`}
                            onClick={() => setOpenCreditsForm(true)}
                          >
                            <RiPencilLine className={`w-4 h-4`} />
                          </span>
                          <span
                            className={`cursor-pointer rounded-full bg-white hover:bg-gray-200 p-1 w-fit`}
                            onClick={() => setOpenAddCreditsForm(true)}
                          >
                            <RiAddLine className={`w-4 h-4`} />
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className={`mt-16 mb-4 text-xl font-semibold`}>
                    {t('projects.Set-options', {ns: 'dashboard'})}
                  </div>
                  <div className={`flex flex-col gap-2`}>
                    {availableOptions.map((option, index) => (
                      <div key={index} className={`flex py-2 px-4 gap-4 hover:bg-gray-100 items-center justify-between`}>
                        <div className={`flex gap-4 items-center`}>
                          <span className={`inline-flex gap-2 items-center`}>
                            {option.picto && option.picto}
                            {t(option.name, {ns: 'dashboard'})}
                          </span>
                          <span>
                            {getOptionStatusTag(option.id)}
                          </span>
                        </div>

                        <div className={``}>
                          <button
                            onClick={() => setOption(option.id, !getOptionStatus(option.id) === true)}
                            className={`flex items-center gap-x-2 border px-2 py-1 rounded cursor-pointer text-sm bg-white ${formLoading ? 'opacity-50' : 'opacity-100'}`}
                            disabled={formLoading}
                          >
                            {formLoading && <RiLoader4Line className="w-4 h-4 animate-spin"/>}
                            {getOptionStatus(option.id) === true ?
                              t('action.Disable', {ns: 'common'})
                              :
                              t('action.Enable', {ns: 'common'})
                            }
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={`mt-16 mb-4 text-xl font-semibold`}>
                    {t('projects.Deployments', {ns: 'dashboard'})}
                  </div>
                  <div className={`flex flex-col gap-2`}>
                    <span
                      className={`w-fit cursor-pointer text-base text-gray-600 hover:underline`}
                      onClick={() => setOpenDeployFront(true)}
                    >
                        {t('projects.Deploy-frontend', {ns: 'dashboard'})}
                      </span>
                  </div>

                  <div className={`flex flex-col gap-2 mt-8`}>
                    <span
                      className={`w-fit cursor-pointer text-base text-gray-600 hover:underline`}
                      onClick={() => setOpenDeploy(true)}
                    >
                        {t('projects.Deploy', {ns: 'dashboard'})}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="z-50 flex shrink-0 items-center mt-2 border-t-2 border-gray-400 bg-gray-100 justify-between pb-6 pt-6 px-4 space-x-2 sticky bottom-0">
            <Button
              actionType="route"
              action="/dashboard/projects/projects"
              theme={formLoading ? 'disabled' : 'simple'}
              size="small"
              icon={<RiArrowLeftLine className="mr-1"/>}
            >
              {t('action.Back')}
            </Button>
          </div>
        </div>
      </LayoutDashboard>
    </>
  )
}

export default ProjectPage