import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
// Firebase
import { FirebaseContext } from "../../firebase"
import Button from "../elements/button/Button";
import {RiCheckLine, RiCloseLine, RiLoader4Line} from "react-icons/ri";
import {capitalizeFirstLetter} from "../../utils/stringUtils";
import InputText from "../forms/input/InputText";
import {PlayNotifications} from "../../redux/notifications/PlayNotifications";

const ChangeModeForm = ({closeForm, project}) => {
  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard']);

  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext);

  const [formLoading, setFormLoading] = useState(false);
  const [branch, setBranch] = useState('');
  const [mode, setMode] = useState('demo');

  const changeMode = () => {
    if(project?.mode !== mode){
      setFormLoading(true);
      const changeModeFunction = firebase.functions.httpsCallable('changeMode');

      changeModeFunction({
        branch: branch?.length > 0 ? branch : '',
        mode: mode,
        project_id: project.id,
      }).then((result) => {
        if(result.data?.success){
          dispatch(
            PlayNotifications(
              {
                display:true,
                theme:"success",
                message: t('success.Change-mode-success', {ns: 'messages'}),
              }
            )
          );
          closeForm();
        } else {
          const error = result.data?.error ? result.data.error : null;
          console.error("Error change mode: ", error);
          const errorMessage = result.data?.message ? result.data.message : 'Erreur';

          dispatch(
            PlayNotifications(
              {
                display: true,
                theme: "danger",
                message: errorMessage,
              }
            )
          );
        }

        setFormLoading(false);
      }).catch((error) => {
        console.error("Error change mode: ", error);
        const errorMessage = error?.message ? error.message : 'Erreur';

        dispatch(
          PlayNotifications(
            {
              display: true,
              theme: "danger",
              message: errorMessage,
            }
          )
        );
        setFormLoading(false);
      });
    }
  }

  useEffect(() => {
    if(project?.mode){
      setMode(project.mode);
    }
  }, [project]);

  useEffect(() => {
    return () => {
      setMode('demo');
      setBranch('');
    }
  }, []);

  return (
    <div
      className="fixed z-30 inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full max-w-100vw w-full max-h-100vh py-10">
      <div
        className="relative p-5 pb-0 border shadow-lg rounded-md bg-white w-4/5 md:w-3/5 lg:w-2/5 max-h-full memoryScrollbar">
        <div className="flex items-center justify-between">
          <h2 className={`text-lg`}>
            {t('projects.Change-mode', {ns: 'dashboard'})}
          </h2>
        </div>

        <div className="mt-3 flex flex-col gap-y-4">
          <div className={`flex gap-x-1`}>
            <span className={``}>{t('projects.Current-mode', {ns: 'dashboard'})}</span>
            <span className={`font-semibold`}>{project.mode ? t(capitalizeFirstLetter(project.mode), {ns: 'common'}) : t('NC', {ns: 'dashboard'})}</span>
          </div>

          <div className="text-gray-900">
            <span className="text-sm font-medium">
              {t('Mode', {ns: 'common'})}
            </span>
            <label htmlFor="mode" className="flex items-center cursor-pointer relative w-fit">
              <span className="text-sm font-bold">{t('Demo', {ns: 'common'})}</span>
              <fieldset className="relative mx-4">
                <input
                  type="checkbox"
                  id="mode"
                  className="sr-only"
                  checked={mode === 'pro' ? true : false}
                  value={mode === 'pro' ? 'pro' : 'demo'}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setMode(e.target.checked ? 'pro' : 'demo')
                  }}
                />
                <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
              </fieldset>
              <span className="text-sm font-bold">{t('Pro', {ns: 'common'})}</span>
            </label>
          </div>

          <div>
            <InputText
              id="branch"
              name="branch"
              label={t('projects.form.branch.label', {ns: 'dashboard'})}
              placeHolder={t('projects.form.branch.placeholder', {ns: 'dashboard'})}
              state={branch}
              setstate={(e) => setBranch(e.target.value)}
              // loading={disabled}
            />
            <p className={`text-xs text-grayPrimary`}>
              {t('projects.form.branch.notice', {ns: 'dashboard'})}
            </p>
          </div>
        </div>

        <div
          className="flex items-end justify-between border-t border-gray-200 pb-5 pt-2 mt-10 sticky top-0 bottom-0 bg-white">
          <div className="flex flex-col gap-4 min480:flex-row">
            <Button
              theme="simple"
              size="small"
              icon={<RiCloseLine className="w-4 h-4 mr-1"/>}
              actionType={"action"}
              action={() => closeForm()}
            >
              {t('action.Back', {ns: 'common'})}
            </Button>
          </div>

          <Button
            theme={formLoading ? 'disabled' : ''}
            size="small"
            icon={formLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin"/> :
              <RiCheckLine className="w-4 h-4 ml-1"/>}
            iconPosition="right"
            actionType="action"
            action={() => changeMode()}
          >
            {t('action.Confirm', {ns: 'common'})}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChangeModeForm;