import React, { useState } from "react"
// Components
import {Fade, Tooltip} from "@mui/material"
import { RiQuestionLine } from "react-icons/ri"
import WhiteTooltip from "../../elements/tooltip/WhiteTooltip";

const Select = ({
  id,
  name,
  label,
  className = '',
  data,
  attribute,
  state,
  setstate,
  labelHidden,
  required,
  placeHolder,
  typeOfValue,
  labelInfo,
  labelInfoLink,
  tooltipData,
  tooltipType = ''
}) => {

  // const [defaultState, setDefaultState] = useState("")

  const defaultHandleChange = (e) => {
    // setDefaultState(e.target.value)
  }

  if (!setstate) setstate = defaultHandleChange

  const handleChange = (e) => {
    switch (typeOfValue) {
      case "object":
        let selectedObj
        const objs = data.filter(obj => obj.id === e.target.value)

        for(const obj of objs) {
          selectedObj = {
            id: obj.id,
            name: obj.name,
            attribute: obj.attribute ? obj.attribute : attribute ? attribute : ''
          }
        }

        setstate(e, selectedObj)
        break
      case "id":
      case "name":
      default:
        setstate(e)
        break
    }
  }

  const getValue = (data) => {
    switch (typeOfValue) {
      case "object":
      case "id":
        return data.id
      case "name":
      default:
        return data.name
    }
  }

  const getDefaultValue = () => {
    if(state){
      if(typeof state === 'object'){
        if( state.id ){
          return state.id
        }

        try {
          //if it is an array of objects
          return state.shift().map(obj => obj.id)
        } catch(e) {}
      } else {
        return state
      }
    }

    return typeof placeHolder === "string" ? placeHolder.trim() : placeHolder.toString()
  }

  return (
    <div className={`${className}`}>
      {labelHidden ? null : (
        <div className={`flex items-center justify-between mb-2 ${labelInfo && 'flex'}`}>
          <label htmlFor={name} className={`block text-sm font-medium ${labelInfo && 'flex'}`}>
            {label}
            {required && " *"}
            {labelInfo && tooltipType === 'html' && (
              <WhiteTooltip
                title={
                  <div className="text-grayPrimary text-sm px-2 py-1 whitespace-pre-line">
                    {tooltipData.map((data, id) => (
                      <div key={id}>{data}</div>
                    ))}
                    {labelInfoLink && (
                      <div>
                        <a
                          href={labelInfoLink.link}
                          target={labelInfoLink.target === 'blank' ? '_blank' : '_self'}
                          rel="noreferrer"
                          className={``}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                          }}
                        >
                          {labelInfoLink.label}
                        </a>
                      </div>
                    )}
                  </div>
                }
                arrow={true}
                placement="right-start"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
              >
                <div>
                  <RiQuestionLine className="ml-2 h-5 w-5 cursor-pointer" />
                </div>
              </WhiteTooltip>
            )}
            {labelInfo && tooltipType !== 'html' && (
              <Tooltip disableFocusListener
                title={
                  <>
                    {tooltipData.map((data, id) => (
                      <div key={id}>{data}</div>
                    ))}
                    {labelInfoLink && (
                      <div>
                        <a
                          href={labelInfoLink.link}
                          target={labelInfoLink.target === 'blank' ? '_blank' : '_self'}
                          rel="noreferrer"
                          className={``}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                          }}
                        >
                          {labelInfoLink.label}
                        </a>
                      </div>
                    )}
                  </>
                }
                arrow={true}
                placement="right-start"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
              >
                <div>
                  <RiQuestionLine className="ml-2 h-5 w-5 cursor-pointer" />
                </div>
              </Tooltip>
            )}
          </label>
        </div>
      )}
      <select
        id={id ? id : name}
        name={name}
        autoComplete={name}
        className={`block w-full sm:text-sm border-gray-300 rounded ${ getDefaultValue() === placeHolder && 'text-grayPrimary' }`}
        value={getDefaultValue()}
        onChange={(e) => handleChange(e)}
        required={required ? required : false}
      >
        <option disabled>{placeHolder}</option>
        {data.map((data, id) => (
          <option
            key={id}
            value={getValue(data)}
          >
            {data.tradKey ? data.tradKey : data.name}
            {!data.name && !data.id && !data.tradKey && data}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
