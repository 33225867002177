import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
// Firebase
import { FirebaseContext } from "../../firebase"
import Button from "../elements/button/Button";
import {RiCheckLine, RiCloseLine, RiLoader4Line} from "react-icons/ri";
import {capitalizeFirstLetter} from "../../utils/stringUtils";
import {PlayNotifications} from "../../redux/notifications/PlayNotifications";
import Select from "../forms/select/SelectSimple";

const ChangePlanForm = ({closeForm, project}) => {
  // Translations
  const { t } = useTranslation(['common', 'messages', 'dashboard']);

  const dispatch = useDispatch();
  const firebase = useContext(FirebaseContext);

  const [formLoading, setFormLoading] = useState(false);
  const [plan, setPlan] = useState('demo');

  const plans = [
    {id: 'fast', name: t('Fast')},
    {id: 'boost', name: t('Boost')},
    {id: 'one', name: t('One')},
  ]

  const changePlan = () => {
    if(project?.plan !== plan){
      setFormLoading(true);
      const changePlanFunction = firebase.functions.httpsCallable('projects-changePlan');

      changePlanFunction({
        plan: plan,
        project_id: project.id,
      }).then((result) => {
        if(result.data?.success){
          dispatch(
            PlayNotifications(
              {
                display:true,
                theme:"success",
                message: t('success.Change-plan-success', {ns: 'messages'}),
              }
            )
          );
          closeForm();
        } else {
          const error = result.data?.error ? result.data.error : null;
          console.error("Error change plan: ", error);
          const errorMessage = result.data?.message ? result.data.message : 'Erreur';

          dispatch(
            PlayNotifications(
              {
                display: true,
                theme: "danger",
                message: errorMessage,
              }
            )
          );
        }

        setFormLoading(false);
      }).catch((error) => {
        console.error("Error change plan: ", error);
        const errorMessage = error?.message ? error.message : 'Erreur';

        dispatch(
          PlayNotifications(
            {
              display: true,
              theme: "danger",
              message: errorMessage,
            }
          )
        );
        setFormLoading(false);
      });
    }
  }

  useEffect(() => {
    if(project?.plan){
      setPlan(project.plan);
    }
  }, [project]);

  useEffect(() => {
    return () => {
      setPlan('fast');
    }
  }, []);

  return (
    <div
      className="fixed z-30 inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full max-w-100vw w-full max-h-100vh py-10">
      <div
        className="relative p-5 pb-0 border shadow-lg rounded-md bg-white w-4/5 md:w-3/5 lg:w-2/5 max-h-full memoryScrollbar">
        <div className="flex items-center justify-between">
          <h2 className={`text-lg`}>
            {t('projects.Change-plan', {ns: 'dashboard'})}
          </h2>
        </div>

        <div className="mt-3 flex flex-col gap-y-4">
          <div className={`flex gap-x-1`}>
            <span className={``}>{t('projects.Current-plan', {ns: 'dashboard'})}</span>
            <span className={`font-semibold`}>{project.plan ? t(capitalizeFirstLetter(project.plan), {ns: 'common'}) : t('NC', {ns: 'dashboard'})}</span>
          </div>

          <div className="text-gray-900">
            <Select
              className={`w-fit`}
              data={plans}
              label={`${t('Plan')}`}
              name="plan"
              placeHolder={plan ? plan : `${t('action.Select', {ns: 'common'})} ${t('plan_one', {ns: 'common'})}`}
              setstate={(e) => setPlan(e.target.value)}
              state={plan}
              typeOfValue="id"
            />
          </div>
        </div>

        <div
          className="flex items-end justify-between border-t border-gray-200 pb-5 pt-2 mt-10 sticky top-0 bottom-0 bg-white">
          <div className="flex flex-col gap-4 min480:flex-row">
            <Button
              theme="simple"
              size="small"
              icon={<RiCloseLine className="w-4 h-4 mr-1"/>}
              actionType={"action"}
              action={() => closeForm()}
            >
              {t('action.Back', {ns: 'common'})}
            </Button>
          </div>

          <Button
            theme={formLoading ? 'disabled' : ''}
            size="small"
            icon={formLoading ? <RiLoader4Line className="w-4 h-4 ml-1 animate-spin"/> :
              <RiCheckLine className="w-4 h-4 ml-1"/>}
            iconPosition="right"
            actionType="action"
            action={() => changePlan()}
          >
            {t('action.Confirm', {ns: 'common'})}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChangePlanForm;