import React from 'react'
import { Link, NavLink } from 'react-router-dom'
// Utils
import {
  NAV_LINK,
  NAV_LINK_ROUTER,
  NAV_ACTION, DEFAULT_MAIN_COLOR
} from '../../../../utils/constantsFront'
import slugify from "react-slugify";
// import {TrackGoogleAnalyticsEvent} from "../../../../utils/googleAnalytics";


const NavBarLink = ({type, link, action, selected, className, children, enableTracking = false, gaTrackingData = {}, theme = 'simple', entityMainColor = DEFAULT_MAIN_COLOR, disableClass = ''}) => {


  const getStyle = () => {
    const defaultStyleLink = `flex items-center text-sm text-grayPrimary hover:text-${entityMainColor} font-medium mx-4`

    switch(type) {
      case NAV_LINK:
      case NAV_ACTION:
        return {
          default: className ? className : defaultStyleLink,
          enable:  "text-memoryBluePrimary underline",
          disable: disableClass.length > 0 ? disableClass : "text-gray-500 no-underline"
        }
      case NAV_LINK_ROUTER:
        return {
          default: className ? className : defaultStyleLink,
          enable:  "text-memoryBluePrimary underline",
          disable: disableClass.length > 0 ? disableClass : "text-gray-500 no-underline"
        }
      default:
        return
    }
  }

  const handleClickTracking = () => {
    if(enableTracking === true){
      // TrackGoogleAnalyticsEvent(
      //   gaTrackingData.category ? gaTrackingData.category : '',
      //   gaTrackingData.action ? gaTrackingData.action : '',
      //   gaTrackingData.label ? gaTrackingData.label : ''
      // );
    }
  }

  const handleClick = (event = null) => {
    event && event.preventDefault()
    handleClickTracking()
    action()
  }

  let style = getStyle()

  switch(type) {
    case NAV_LINK:
      return (
        <Link 
          to={link ? link : ''} 
          onClick={action ? () => handleClick() : null}
          className={`${style.default} ${selected ? style.enable : style.disable}`}
        >
          {children}
        </Link> 
      )
    case NAV_LINK_ROUTER:
      return (
        <NavLink 
          to={link? link : ''}
          className={(isActive) => (isActive ? `${style.default} ${style.enable}` : `${style.default} ${style.disable}`)}
          onMouseEnter={(e) => {
            if( theme === 'secondary' && entityMainColor?.length > 0 && entityMainColor.charAt(0) === '#' ) {
              e.target.style.backgroundColor = entityMainColor
              e.target.style.color = 'white'
            }
          }}
          onMouseLeave={(e) => {
            if( theme === 'secondary' && entityMainColor?.length > 0 && entityMainColor.charAt(0) === '#' ) {
              e.target.style.backgroundColor = 'white'
              e.target.style.color = '#52527A'
            }
          }}
          onClick={handleClickTracking}
        >
          {children}
        </NavLink>  
      )
    case NAV_ACTION:
      return (
        <a
          id={`${slugify(children)}`}
          className={`${style.default} ${selected ? style.enable : style.disable}`}
          onClick={e => handleClick(e)}
          onMouseEnter={(e) => {
            if( theme === 'secondary' && entityMainColor?.length > 0 && entityMainColor.charAt(0) === '#' && e.target.id.length > 0 ) {
              e.target.style.backgroundColor = entityMainColor
              e.target.style.color = 'white'
            }
          }}
          onMouseLeave={(e) => {
            if( theme === 'secondary' && entityMainColor?.length > 0 && entityMainColor.charAt(0) === '#' && e.target.id.length > 0 ) {
              e.target.style.backgroundColor = 'white'
              e.target.style.color = '#52527A'
            }
          }}
        >
          {children}
        </a>
      )
    default:
      return
  }

}

export default NavBarLink
