import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
// Component
import { Link } from "react-router-dom"
import Loader from "../elements/loader/PageContentLoader"
import ContentIsEmpty from "../elements/divContainer/ContentIsEmpty"
import moment from "moment";
import {RiSortAsc, RiSortDesc} from "react-icons/ri";


const TableProjects = ({ list, dataLoaded }) => {
  
  // Translations
  const { t } = useTranslation('common')

  const [dataOrdered, setDataOrdered] = useState([]);
  const [order, setOrder] = useState('name');
  const [orderSense, setOrderSense] = useState('asc');

  const changeOrder = (column) => {
    switch (column) {
      case 'id':
      case 'name':
      case 'mode':
      case 'date':
        if(order!==column){
          setOrder(column);
          setOrderSense('asc');
        } else {
          setOrderSense(orderSense === 'asc' ? 'desc' : 'asc');
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setDataOrdered(list);
  }, [list]);

  useEffect(() => {
    let data = [...dataOrdered];
    switch (order) {
      case 'id':
        data = orderSense === 'asc' ? data.sort((a,b) => a.id.localeCompare(b.id, { sensitivity: 'base' })) : data.sort((a,b) => b.id.localeCompare(a.id, { sensitivity: 'base' }));
        break;
      case 'name':
        data = orderSense === 'asc' ? data.sort((a,b) => a.name.localeCompare(b.name, { sensitivity: 'base' })) : data.sort((a,b) => b.name.localeCompare(a.name, { sensitivity: 'base' }));
        break;
      case 'mode':
        data = orderSense === 'asc' ? data.sort((a,b) => a.mode?.localeCompare(b.mode, { sensitivity: 'base' })) : data.sort((a,b) => b.mode?.localeCompare(a.mode, { sensitivity: 'base' }));
        break;
      case 'date':
        data = orderSense === 'asc' ? data.sort((a, b) =>  a.created_at > b.created_at ? 1 : -1) : data.sort((a, b) =>  a.created_at < b.created_at ? 1 : -1);
        break;
      default:
        return;
    }

    setDataOrdered(prevState => {return data});
  }, [order, orderSense]);

  return (
    <div className="flex flex-col overflow-auto pr-2.5 relative memoryScrollbar">
      {/* GRID HEAD */}
      <div className="grid grid-cols-4 w-full text-sm font-bold bg-gray-200 sticky top-0 z-10">
        <span
          className={`p-2 cursor-pointer flex items-center gap-2`}
          onClick={() => changeOrder('id')}
        >
          {t('Id')}
          {order === 'id' && (
            orderSense === 'asc' ?
              <RiSortAsc className={`w-3 h-3`} />
              :
              <RiSortDesc className={`w-3 h-3`} />
          )}
        </span>
        <span
          className={`p-2 cursor-pointer flex items-center gap-2`}
          onClick={() => changeOrder('name')}
        >
          {t('Name')}
          {order === 'name' && (
            orderSense === 'asc' ?
              <RiSortAsc className={`w-3 h-3`} />
              :
              <RiSortDesc className={`w-3 h-3`} />
          )}
        </span>
        <span
          className={`p-2 cursor-pointer flex items-center gap-2`}
          onClick={() => changeOrder('mode')}
        >
          {t('Mode')}
          {order === 'mode' && (
            orderSense === 'asc' ?
              <RiSortAsc className={`w-3 h-3`} />
            :
              <RiSortDesc className={`w-3 h-3`} />
          )}
        </span>
        <span
          className={`p-2 cursor-pointer flex items-center gap-2`}
          onClick={() => changeOrder('date')}
        >
          {t('date.Creation-date', {ns: 'common'})}
          {order === 'date' && (
            orderSense === 'asc' ?
              <RiSortAsc className={`w-3 h-3`} />
              :
              <RiSortDesc className={`w-3 h-3`} />
          )}
        </span>
      </div>

      {/* GRID BODY */}
      <div className="flex flex-col">

        {dataOrdered && dataLoaded ?
          <>
            {dataOrdered.length === 0 ?
              <ContentIsEmpty>
                {t('No-element')}
              </ContentIsEmpty>
            :
              dataOrdered.map((item) => (
                <div key={item.id} className={`grid grid-cols-4 w-full border-b hover:bg-gray-100`}>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.id}>
                      {item.id}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.name ? item.name : item.id}>
                      {item.name ? item.name : item.id}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.mode ? item.mode : 'NC'}>
                      {item.mode ? item.mode : 'NC'}
                    </span>
                  </Link>
                  <Link
                    to={`/dashboard/projects/projects/${item.id}`}
                    className="group relative flex w-full text-sm"
                  >
                    <span className="dashboard__gridItem dashboard__gridItem--beforeHide font-normal" title={item.created_at ? moment(item.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}>
                      {item.created_at ? moment(item.created_at.toDate()).format('DD/MM/YYYY - H:mm:ss') : t('NC', {ns: 'dashboard'})}
                    </span>
                  </Link>
                </div>
              ))
            }
          </>
        :
          <Loader className="h-48" />
        }
      </div>
    </div>
  )
}

export default TableProjects